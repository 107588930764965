import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Alert from 'reactstrap/lib/Alert';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';

type Props = {
  productFilter?: (p: Article) => any;
};

const TicketListing: React.FC<Props> = ({ productFilter = (p) => p }) => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="ticket-listing">
      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
        <body className="ticket-listing-page inverse-navbar" />
        <script>{`fbq('track', 'ViewContent', {'content_category': 'Ticket Listing'});`}</script>
      </Helmet>
      <div className="text-center">
        <img src={require('./assets/redesign0524/nie_wieder_sommer_header.jpg')} alt="" className="img-fluid" />
      </div>

      <Container className="pt-3">
        <ContentrArea id="before-listing" />
      </Container>

      <Container className="py-3 list-page">
        <h1 className="mb-4 text-center">{t(`TICKETLISTING.TITLE`)}</h1>

        {/* <Container className="px-0 mb-5">
          <Row className="mb-2 d-flex justify-items-center justify-content-center listing-buttons-container">
            <Col className="mt-2">
              <Link to="/tickets" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
                {'Alle Shows'}
              </Link>
            </Col>
            <Col className="mt-2">
              <Link to="/tickets?tagId=18" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
                {'Nie wieder W.A.C.H.'}
              </Link>
            </Col>
          </Row>
        </Container> */}

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry
            id="ticket-listing"
            products={tickets.filter(productFilter)}
            type="list"
            filters="off"
            showLocation
            showCity
            // showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
